'use strict';

/**
 * Update the column structure according to the value passed in 'no-of-columns'
 *
 * @param {Object} $this - Grid element
*/
function updateGridClasses($this) {
    let $region = $this.closest('.region');
    let numberOfColumns = $this.data('no-of-columns');
    let desktopGrid = numberOfColumns;
    let mobileGrid = numberOfColumns;

	if (parseInt(numberOfColumns, 10) > 2) {
        mobileGrid = 2;
    }

	switch (parseInt(numberOfColumns, 10)) {
		case 4:
			desktopGrid = 4;
			break;
		case 3:
			desktopGrid = 3;
			break;
		case 2:
			desktopGrid = 2;
			break;
	}

    $region.removeClass('col-sm-4').addClass('col-sm-' + (desktopGrid * 3));
    $region.removeClass('col-6').addClass('col-' + (mobileGrid * 6));
}

function pageDesingerCompInit() {
    // eslint-disable-next-line
    $('.pvh-image-and-text-with-links').each(function (i, obj) {
        updateGridClasses($(this));
    });
    // eslint-disable-next-line
    $('.raw-html-component-container').each(function (i, obj) {
        let $this = $(this);
        let isMobileOnly = $this.data('mobileonly');
        let isDesktopOnly = $this.data('desktoponly');
        if (isMobileOnly && !isDesktopOnly) {
            $this.addClass('d-md-none');
            $this.closest('.region').addClass('d-md-none');
        }
        if (isDesktopOnly && !isMobileOnly) {
            $this.addClass('d-none d-md-block');
            $this.closest('.region').addClass('d-none d-md-block');
        }
        updateGridClasses($this);
    });

    $('.inlinePLP-wrapper').each(function () {
        let $region = $(this).closest('.region');
        $region.removeClass('col-6').addClass('col-12');
    });
}

module.exports = {
    init: function () {
        $(document).ready(function () {
            var $pageType = $('.page.current').data('page');
            if ($pageType !== 'product') {
                $('.ds-breadcrumb').removeClass('ds-pdp-breadcrumb');
            }
            pageDesingerCompInit();
            $('body').on('pagedesigner:initComponent', (e) => {
                pageDesingerCompInit();
            });
        });
    }
};
