'use strict';
var base = require('base_global/product/base');
var productGlobalVariable;
var slickClickFlag = false;
var tealiumEnable = require('int_tealium_sfra/tealiumEnable');
var quantityHelper = require('base_global/quantitySelectorHelper');
var quickViewHelper = require('base_global/product/quickViewHelper');
var utils = require('base_global/utils');
var constants = require('base_global/constants');

/**
 * One Size product identification
 *
 * @param {string} sizeVariation - to find out product is one size or not
*  @return {boolean} - boolean for one size product identification.
 */
function isOneSizeProduct(sizeVariation) {
    return sizeVariation.id.toLowerCase() === 'one size' || sizeVariation.id.toLowerCase() === 'onesize';
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }

    var htmlString =
        '<!-- Modal -->' +
        '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">' +
        '<span class="enter-message sr-only" ></span>' +
        '<div class="modal-dialog choose-bonus-product-dialog" ' +
        'data-total-qty="' +
        data.maxBonusItems +
        '"' +
        'data-UUID="' +
        data.uuid +
        '"' +
        'data-pliUUID="' +
        data.pliUUID +
        '"' +
        'data-addToCartUrl="' +
        data.addToCartUrl +
        '"' +
        'data-pageStart="0"' +
        'data-pageSize="' +
        data.pageSize +
        '"' +
        'data-moreURL="' +
        data.showProductsUrlRuleBased +
        '"' +
        'data-bonusChoiceRuleBased="' +
        data.bonusChoiceRuleBased +
        '">' +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        '    <span class="">' +
        data.labels.selectprods +
        '</span>' +
        '    <button type="button" class="close" data-dismiss="modal">' +
        '        <span aria-hidden="true">&times;</span>' +
        '        <span class="sr-only"> </span>' +
        '    </button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '<div class="modal-footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the 'Add to Cart' button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('#quickViewModal .modal-close button.close').trigger('click');
    $('.minicart').trigger('count:update', response);
    utils.updateCartCount(response.quantityTotal);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (
        response.newBonusDiscountLineItem &&
        Object.keys(response.newBonusDiscountLineItem).length !== 0
    ) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append('<div class="add-to-cart-messages"></div>');
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' +
            messageType +
            ' add-to-basket-alert text-center" role="alert">' +
            response.message +
            '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

/**
 * @param {string} attr selecrts colour
 * @param {boolean} strikeThroughStatus return strike through status value
 */
function processNonSwatchValues(attr, strikeThroughStatus, isTeaser) {
    attr.values.forEach(function (attrValue, index) {
        var $attrValue = $('#' + attr.id + 'item' + index);
        const attributeId = attr.id + 'item' + index;
        var isSelectableLabel = $(`#${attributeId}`);   //eslint-disable-line
        $attrValue.attr('data-url', attrValue.url).removeAttr('disabled');
        if (!attrValue.selectable && !isTeaser) {
            $attrValue.attr('disabled', '');
            if (!strikeThroughStatus && !isTeaser) {
                $(isSelectableLabel).css('display', 'none');
            }
        } else {
            $(isSelectableLabel).css('display', 'flex');
        }
        if (attrValue.selected) {
            $('label.' + attr.id).html(
                '<span class="variation__attr--name body-font">' + attr.displayName + '</span><span class="variation__attr--value body-font">' + attr.displayValue + '</span>'
            );
            $attrValue.attr('aria-checked', 'true');
        } else {
            $attrValue.attr('aria-checked', 'false');
        }
    });
}
/**
 * @param {string} attrs selecrts colour
 * @param {boolean} strikeThroughStatus return strike through status value
 */
function updateAttrs(attrs, strikeThroughStatus, isTeaser) {
    var isOneSize = isOneSizeProduct(attrs[1].values[0]);
    attrs.forEach(function (attr) {
        if (attr.swatchable) {
            quickViewHelper.processSwatchValues(attr, strikeThroughStatus, null, isTeaser);
        } else {
            processNonSwatchValues(attr, strikeThroughStatus, isTeaser);
        }
        if (isOneSize) {
            if (!attr.swatchable && attr.displayValue === '') {
                $('label.' + attr.id).html(
                    '<span class="variation__attr--name">' + attr.displayName + '</span><span class="variation__attr--value body-font">' + attr.values[0].displayValue + '</span>'
                );
            }
        } else if (!attr.swatchable && attr.displayValue === '') {
            $(`label.${attr.attributeId}`).html(`<span class="variation__attr--name">Select ${attr.displayName}</span>`);
        }
    });
}

/**
 * @param {String} message - error message
 */
function handleDataError(message) {
    var $pageQuantityError = $('.page-quantity-error');
    $pageQuantityError.html(utils.showErrorMessage(message));
    $pageQuantityError[0].scrollIntoView({ behavior: 'smooth' });
}

/**
 * @param {Object} product - response object
 */
function updateImages(product) {
    var imgResponse = '';
    var  imgObj = {};
    if(product.imageDimentions.desktop.product) {
        imgObj.desktop = product.imageDimentions.desktop.product;
        imgObj.mobile = product.imageDimentions.mobile.product;
    } else {
        imgObj.desktop = product.imageDimentions.desktop.quickBuy.product;
        imgObj.mobile = product.imageDimentions.desktop.quickBuy.product;
    }
    product.images.large.forEach((item, index) => {
        imgResponse += '<div class="' + (index === 0 ? 'active' : '') + '">' +
            utils.renderPictureTag(item, {devices: {desktop: imgObj.desktop,mobile: imgObj.mobile}, className:"quickview-slick-img"}) +
            '</div>';
    });
    $('.js-quickview-slick').slick('removeSlide', null, null, true);
    $('.js-quickview-slick').slick('slickAdd', imgResponse);
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 */
function handleVariantResponse(response) {
    if (response.product.variationAttributes) {
        updateAttrs(
            response.product.variationAttributes,
            response.product.strikeThroughStatus,
            response.product.isTeaserProduct
        );
    }
    $('.quickview__modal__head--name').html(
        '<a class="quickview__modal-heading-link" href=' + response.product.selectedProductUrl + '>' + response.product.productName + '</a>'
    );
    // Update Promotions
    $('.quickview__modal__body--inner').find('.product-promotions').empty().html(response.product.promotionsHtml);
    updateImages(response.product);
    quickViewHelper.updateProductURL(response.product, $('#quickViewModal'));
    $('.quickview__modal__body--prices').empty().html(response.product.price.html);
    if (!response.product.orderable) {
        $('#quickViewModal .sales .value').html(constants.messages.OOS_status);
    }
    $(
        'button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global'
    )
        .trigger('product:updateAddToCart', {
            product: response.product
        })
        .trigger('product:statusUpdate', response.product);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#quickViewModal').length !== 0) {
        $('#quickViewModal').remove();
    }
    var htmlString =
        '<!-- Modal -->' +
        '<div class="modal slide-in" id="quickViewModal" role="dialog">' +
        '<span class="enter-message sr-only" ></span>' +
        '<div class="modal-dialog quick-view-dialog quickview__modal">' +
        '<!-- Modal content-->' +
        '<div class="modal-content quickview__modal--content">' +
        '<div class="modal-close">' +
        '    <button type="button" class="close" data-dismiss="modal">' +
        '        <span aria-hidden="false">&times;</span>' +
        '        <span class="sr-only"> </span>' +
        '    </button>' +
        '</div>' +
        '<div class="modal-carousel"></div>' +
        '<div class="modal-header quickview__modal__head">' +
        '<div class="row"></span>' +
        '<div class="product-detail__imgbadges quickvie-head col-12">Pre-order</div>' +
        '<h1 class="quickview__modal__head--name"></h1>' +
        '</div>' +
        '</div>' +
        '<div class="modal-body quickview__modal__body"></div>' +
        '<div class="quickview__modal__footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
}

/**
 * @typedef {Object} QuickViewHtml
 * @property {string} body - Main Quick View body
 * @property {string} footer - Quick View footer content
 */
function quickviewSlick() {
    setTimeout(function () {
        $('.js-quickview-slick').slick({
            autoplay: false,
            rows: 0,
            speed: 300,
            dots: false,
            infinite: false,
            accessibility: true,
            variableWidth: true,
            slidesToShow: 1.7,
            slidesToScroll: 1,
            arrows: true,
            prevArrow:
                '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" aria-disabled="false" style=""><span class="icon-prev icon-arrow-left"></span></button>',
            nextArrow:
                '<button class="slick-next slick-arrow slick-disabled" aria-label="Next" type="button" aria-disabled="true" style=""><span class="icon-next icon-arrow-right"></span></button>'
        });
    }, 150);
}

/**
 * Handle image badge for quick view
 * @param {string} imgbadges - image badge string
 * @param {Object} responseData - Response data badge image
 * @return {imgbadgesText} - Image badge text
 */
// eslint-disable-next-line
function imageBadge(imgbadges, responseData) {
    var imgbadgesText = imgbadges;
    if (responseData.badgeSkuLevelStatus.newBadge || responseData.badgeSkuLevelStatus.imageBadge1 || responseData.badgeSkuLevelStatus.imageBadge2) {
        imgbadgesText += ' , ';
    } else {
        imgbadgesText += '';
    }
    return imgbadgesText;
}

/**
 * Update Badges
 *
 * @param {Object} responseData - Data response from AJAX call
 */
function updateBadges(responseData) {
    var isOneSize = isOneSizeProduct(responseData.variationAttributes[1].values[0]);
    var availabilityValue = '';
    var preOrderStatus = '';
    var availabilityMessages = responseData.availability.messages;
    var isAvailability = responseData.available;
    var imgbadges = '';
    var stockBadges = '';
    var saleBadges = '';
    var availabilityMessageExists = false;
    var responseMessage = responseData.badgeSkuLevelStatus.preOrderMessage;
    var responseMessageDate = responseData.badgeSkuLevelStatus.preOrderMessageDate;
    if (responseData.badgeSkuLevelStatus.preOrderStatus) {
        preOrderStatus += '<div class="pre-order"> <span>' + responseMessage + '</span> <span class="pre-order-date">' + responseMessageDate + '</span> </div>';
    }
    availabilityMessages.forEach(function (message) {
        if (responseData.badgeSkuLevelStatus.availabilityStatus) {
            availabilityValue += `<li><div class="${isAvailability ? '' : 'custom-error'}">${message}</div></li>`;
        }
    });

    availabilityMessageExists = availabilityValue && (responseData.readyToOrder || isOneSize);

    if (responseData.brand) {
        imgbadges += responseData.brand;
    } else if (responseData.subBrand) {
        imgbadges += responseData.subBrand;
    }

    if (!responseData.badgeSkuLevelStatus.availabilityStatus && responseData.availability.inStockValue.length === 0) {
        stockBadges += '';
    } else if (responseData.availability.inStockValue.length !== 0) {
        stockBadges += `<div class="product-detail__salebadges-text"> ${responseData.availability.inStockValue} </div>`;
    }

    if (responseData.badgeSkuLevelStatus.finalSalestatus && !responseData.isTeaserProduct) {
        saleBadges += '<div class="custom-error product-detail__salebadges-text quickview__modal__body--finalBadge">Final Sale</div>';
    }
    if (responseData.readyToOrder || isOneSize) {
        $('.availability-msg').removeClass('error').empty().html(availabilityValue);
    } else {
        $('.availability-msg').removeClass('error').empty().html('');
    }
    $('.preorder-status').empty().html(preOrderStatus);
    $('.product-detail__imgbadges').empty().html(imgbadges);
    $('.product-detail__stockBadges').empty().html(stockBadges);
    $('.product-detail__salebadges').empty().html(saleBadges);

    var showFullDetailLink = true;

    // Leaving the rest of the check for later use
    utils.setVariationAttrMargin($('.attribute-item', '.product-quickview').last(), showFullDetailLink || stockBadges || saleBadges || availabilityMessageExists);
}

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var carousel = $html.find('.quickview__modal__images');
    var addToCart = $html.find('.desk-footer .cart-and-ipay');
    var footer = addToCart;
    return { body: body, footer: footer, carousel: carousel };
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 * @param {number} productTileIndex - product tile count
 * @param {boolean} searchRedirect - to check redirect for SRP
 */
function fillModalElement(selectedValueUrl, productTileIndex, searchRedirect) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: selectedValueUrl,
        data: { showQuickView: true },
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);
            $('.modal-body').empty();
            $('#quickViewModal .modal-body').html(parsedHtml.body);
            $('#quickViewModal .modal-carousel').html(parsedHtml.carousel);
            $('.quickview__modal__footer').html(parsedHtml.footer);
            if (!data.product.orderable) {
                $('#quickViewModal .sales .value').html(constants.messages.OOS_status);
            }
            $('.full-pdp-link').text(data.quickViewFullDetailMsg);
            $('.full-pdp-star-rating-link').attr('href', data.productUrl);

            $('#quickViewModal .full-pdp-link').attr('href', data.productUrl);
            $('#quickViewModal h1.quickview__modal__head--name').html(
                '<a class="quickview__modal-heading-link" href=' + data.product.selectedProductUrl + '>' + data.product.productName + '</a>'
            );
            $('#quickViewModal .size-chart').attr('href', data.productUrl);
            $('#quickViewModal .modal-close .close .sr-only').text(
                data.closeButtonText
            );
            $('#quickViewModal .enter-message').text(data.enterDialogMessage);
            const $quickviewModal = $('#quickViewModal');
            utils.openModal($quickviewModal);
            $('body').trigger('quickview:ready');
            quickviewSlick();
            productGlobalVariable = data.product;
            updateBadges(data.product);
            quickViewHelper.updateProductURL(data.product, $('#quickViewModal'));
            $.spinner().stop();
            setTimeout(function () {
                $('#quickViewModal .close').trigger('focus');
            }, 500);
            // tealium event
            tealiumEnable.getAnalyticsData('quickViewEvent', data, productTileIndex, searchRedirect);
        },
        error: function () {
            $.spinner().stop();
        }
    });
}
/**
 *
function to handle previous error response from server
 * @param {string} attribute_id -
 *
 */
function handlePreviousErrorResponse(attribute_id) {
    $('.' + attribute_id + ' span.variation__attr--name').removeClass('modal-error-color');
    $('li[role="radio"].variant-' + attribute_id + ' span').removeClass('modal-error-color');
    $($('.' + attribute_id).parents('#pdpAttribute')).find('div.variant-selector-container .variant-item').removeClass('modal-error-border');
    $('.variant-error-' + attribute_id).removeClass('show').addClass('hide');
}

/**
 * @param {string} selectedValueUrl selects size
 * @param {jQuery} productContainer - DOM element for current product
 * @param {string} eventName event name on variation select
 * @param {string} attrId - attribute ID of product
 */
function attributeSelect(selectedValueUrl, productContainer, eventName, attrId) {
    // $productContainer
    if (selectedValueUrl) {
        var $quickviewModalContent = $('.quickview__modal--content');
        var $defaultImage = $('.primary-images > #defaultImage');
        if ($defaultImage) $defaultImage.hide();
        $.ajax({
            url: selectedValueUrl + '&showQuickView=true',
            method: 'GET',
            success: function (data) {
                var isOneSize = isOneSizeProduct(data.product.variationAttributes[1].values[0]);
                var quickViewModalPos = $quickviewModalContent.scrollTop();
                var addToBagButtonText = $('.add-to-cart-hidden').val();
                var productData = data.product;
                var productId = productContainer.data('pid');
                productGlobalVariable = data.product;
                var buttonSalesPrice = data.product.isAddToBagPrice && data.product.price.salesDisplayPrice ? ' - ' + data.product.price.salesDisplayPrice : '';
                if (buttonSalesPrice === '') {
                    buttonSalesPrice = data.product.isAddToBagPrice && data.product.price.showSavings ? ' - ' + data.product.price.salesDisplayRange : '';
                }
                $('.page-quantity-error').empty();
                handleVariantResponse(data);
                updateBadges(data.product);
                quantityHelper.updateQuantities(data.product, productContainer, productId);
                handlePreviousErrorResponse(attrId);
                $('.error-text').remove();
                if (isOneSize && data.product.oneSizeSKUID) {
                    $('.add-to-cart-global').attr('data-cur-pid', data.product.oneSizeSKUID);
                } else {
                    $('.add-to-cart-global').attr('data-cur-pid', data.product.id);
                }

                $('.modal.show .full-pdp-link').attr('href', data.product.selectedProductUrl);
                if (productData.badgeSkuLevelStatus.preOrderStatus) {
                    $('button.add-to-cart-global').html(productData.badgeSkuLevelStatus.preOrderBadge + buttonSalesPrice);
                } else {
                    $('button.add-to-cart-global').html(addToBagButtonText + buttonSalesPrice);
                }
                $('button.add-to-cart-global').attr('data-category-id', data.product.productCgId);
                // null check to disable/enable addToBag CTA
                utils.disableButton($('button.add-to-cart-global'), data.product.price.salesDisplayPrice);

                var $primaryImages = $('.primary-images');
                if ($primaryImages) {
                    if ($primaryImages.outerHeight() === 0) {
                        $('.primary-images > #defaultImage').show();
                    } else {
                        $('.primary-images > #defaultImage').hide();
                    }
                }
                $quickviewModalContent.scrollTop(quickViewModalPos);
                if (eventName !== '') {
                    tealiumEnable.getAnalyticsData('getVariationSelectedData', productData, eventName);
                }
            },
            error: function () {
                $quickviewModalContent.spinner().stop();
            }
        });
    }
}

/**
 * Handles the errors for the variation categories
 */
function handleVariantErrorMessage() {
    var $variantError;
    $('#quickViewModal .variant-list, #editProductModal .variant-list, #editProductModalMob .quickbuy--variant-list').each(function () {
        var $this = $(this);
        if ($this.hasClass('colors-variant-list')) {
            return;
        }
        var mode_of_variant = $this.data('display-id');
        if ($this.has('.variant-item[aria-checked="true"], .variant-color-item[aria-checked="true"]').length) {
            $('.' + mode_of_variant + ' span.variation__attr--name').removeClass('modal-error-color');
            $('li[role="radio"].variant-' + mode_of_variant).removeClass('modal-error-border');
            $('li[role="radio"].variant-' + mode_of_variant + ' span').removeClass('modal-error-color');
            $this.closest('#pdpAttribute').find('.variant-error').removeClass('show');
            $this.closest('#pdpAttribute').find('.variant-error').addClass('hide');
        } else {
            $variantError = $this.closest('#pdpAttribute').find('.variant-error');
            $variantError.removeClass('hide').addClass('show');
            $('.' + mode_of_variant + ' span.variation__attr--name').addClass('modal-error-color');
            $('li[role="radio"].variant-' + mode_of_variant).addClass('modal-error-border');
            $('li[role="radio"].variant-' + mode_of_variant + ' span').addClass('modal-error-border');
        }
    });

    if ($variantError && $variantError.length > 0) {
        $variantError[0].scrollIntoView({ behavior: 'smooth' });
    }
}

/**
 * function to handle validation UI on Quantity Selector
 * @param {string} mode_of_variant - when user clicks on the add to bag button without selecting size then gives the value as size in string
 */
function updateVariantOnQuantitySelectorStatus(mode_of_variant, mode_of_variant_value) {
    if ($('.' + mode_of_variant + ' span.variation__attr--name').html()) {
        const isVariantLabelAvailable = $('.' + mode_of_variant + ' span.variation__attr--name').html().replace(/\s+/g, ' ').trim();
        if (isVariantLabelAvailable.toLowerCase() === 'select ' + mode_of_variant_value.toLowerCase() + '') {
            $('.' + mode_of_variant + ' span.variation__attr--name').addClass('modal-error-color');
            $($('.' + mode_of_variant).parents('#pdpAttribute')).find('div.variant-selector-container .variant-item').addClass('modal-error-border');
            $('li[role="radio"].variant-' + mode_of_variant + ' span').addClass('modal-error-border');
            $('.variant-error-' + mode_of_variant).removeClass('hide');
            $('.variant-error-' + mode_of_variant).addClass('show').trigger('focus');
            $('.quickview__modal--content').animate({
                scrollTop: $('.' + mode_of_variant).parents('#pdpAttribute').find('.variant-error').offset().top
            }, 500);
        }
    }
}

module.exports = {
    ...base,
    showQuickview: function () {
        $('body').on('click', '.quickview', function (e) {
            var searchRedirect = $('div.quickview').data('searchcheck');
            e.preventDefault();
            var selectedValueUrl = $(this).closest('div.quickview').attr('data-url');
            var productTileIndex = $(this).closest('.productTileShow').data('productindex');
            $(e.target).trigger('quickview:show');
            getModalHtmlElement();
            fillModalElement(selectedValueUrl, productTileIndex, searchRedirect);
        });
        $('body').on('hidden.bs.modal', '#quickViewModal', function () {
            $(this).remove();
        });
    },

    addToCart: function () {
        $(document).on(
            'click',
            'button.add-to-cart, button.add-to-cart-global',
            function () {
                var pid = $(this).attr('data-cur-pid');
                if (pid === undefined) {
                    pid = $(this).attr('data-pid');
                }
                var qty = $('.quantity-dropdown option:selected').val();
                var addToCartUrl = $('.add-to-cart-url').val();
                var readyToOrder = $('.add-to-cart-global').attr('data-readytorder');
                var pageType = $('.page.current').data('page');
                $('.error-text').remove();
                var csrf_token = $('.csrf-token').attr('data-csrf-value');
                var cgid = $(this).attr('data-category-id');
                var form = {
                    pid: pid,
                    quantity: qty,
                    pageType: pageType,
                    csrf_token: csrf_token,
                    cgid: cgid
                };
                handleVariantErrorMessage();
                if (addToCartUrl && readyToOrder === 'true') {
                    $.ajax({
                        url: addToCartUrl,
                        method: 'POST',
                        data: form,
                        success: function (data) {
                            if(data.error) {
                                handleDataError(data.message);
                            } else {
                                $('.page-quantity-error').empty();
                                handlePostCartAdd(data);
                                $('body').trigger('product:afterAddToCart', data);
                                tealiumEnable.getAnalyticsData('getAddToCartData', productGlobalVariable, data.cart.basketUUID);
                                $.spinner().stop();
                            }
                        },
                        error: function (data) {
                            let message = data.responseJSON.message;
                            let errorHandling = function () {
                                $.spinner().stop();
                                $('.error-text').remove();
                                $('.quickview__modal__footer').prepend(`<span class="error-text page-error d-none"><svg class="icon-utility-alert-red"><use xlink:href="#alert-red"></use></svg>${message}</span>`);
                                $('#quickViewModal .error-text').removeClass('d-none').trigger('focus');
                                tealiumEnable.getAnalyticsData('serverError', data.responseJSON.errorCode);
                            }
                            if (data.responseJSON.csrfError) {
                                utils.handleCsrfToken(errorHandling);
                            } else {
                                errorHandling();
                            }
                        }
                    });
                }
            }
        );
    },

    colorAttribute: function () {
        $(document).on(
            'click',
            '.variant-color-item:not([disabled])', function (e) {
                e.preventDefault();
                var $productContainer = $('.product-detail');
                var selectedValueUrl = $(this).attr('data-url');
                var eventName = 'product_change_color';
                var selectedAttrId = $(this).attr('data-display-id');
                attributeSelect(selectedValueUrl, $productContainer, eventName, selectedAttrId);
                $('.quickview__modal--content').animate({
                    scrollTop: $('body').offset().top
                }, 500);
            }
        );

        $(document).on('keyup', '.variant-color-item:not([disabled])', function (event) {
            if (event.code === 'Enter') {
                $(this).trigger('click');
            }
        });
    },

    selectAttribute: function () {
        $(document).on('click', '.variant-item:not([disabled])', function (e) {
            e.preventDefault();
            var $productContainer = $('.product-detail');
            var selectedValueUrl = $(this).attr('data-url');
            var eventName = 'product_change_size';
            var selectedAttrId = $(this).attr('data-display-id');

            attributeSelect(selectedValueUrl, $productContainer, eventName, selectedAttrId);
        });

        $(document).on('keyup', '.variant-item:not([disabled])', function (event) {
            if (event.code === 'Enter') {
                $(this).trigger('click');
            }
        });
    },

    quantitySelect: function () {
        $(document).on('change', '.quantity-dropdown select', function (e) {
            e.preventDefault();
            var $productContainer = $('.product-detail');
            var selectedValueUrl = $('option:selected', $(this)).data('url');
            if (quantityHelper.isProductSetavailable()) {
                $productContainer = $(this).closest('.set-item.productSet-wrapper');
            }
            var eventName = '';
            attributeSelect(selectedValueUrl, $productContainer, eventName, '-');
        });
    },

    updateAddToCart: function () {
        $('body').on('product:updateAddToCart', function (e, response) {
            // update local add to cart (for sets)
            var isOneSize = isOneSizeProduct(response.product.variationAttributes[1].values[0]);
            $('.add-to-cart-global').attr('data-readytorder', (response.product.readyToOrder || isOneSize));
        });
    },

    quickViewSlickAnalytics: function () {
        $(document).on('click', '.js-quickview-slick .slick-arrow', function () {
            if (!slickClickFlag) {
                var eventName = 'product_image_interaction';
                tealiumEnable.getAnalyticsData('getVariationSelectedData', productGlobalVariable, eventName);
                slickClickFlag = true;
            }
        });
        $(document).on('hidden.bs.modal', '#quickViewModal', function () {
            slickClickFlag = false;
        });
    },

    quickViewQuantityErrorWarning: function () {
        $(document).on('click', '.js-quantitySelector', function (e) {
            let containerData = e.target.nodeName !== 'SELECT' ? $(e.target).siblings('select') : e.target;
            if ($(containerData).attr('disabled')) {
                const variants = $('#quickViewModal').find('.variant-list:not([data-display-id="colorCode"])').toArray();
                variants.forEach(elem => {
                    const variant = $(elem)[0].dataset.displayId;
                    const variantValue = $(elem)[0].dataset.displayValue;
                    let variantItem = $('section.attributes').find('label.' + variant);
                    if (variantItem.length) {
                        updateVariantOnQuantitySelectorStatus(variant, variantValue);
                    }
                });
            }
        });
    },
    handleVariantErrorMessage
};