var quantityHelper = require('../quantitySelectorHelper');


/**
 * Process the Non-Swatch label attributes
 * @param {jQuery} productSetContainer - DOM container for a given product in product set
 * @param {Object} attr - Attribute
 * @param {string} swatchHtml - Swatch HTML
 * @param {string} label - label
 * @param {Object[]} $attrValue - Attribute coded value
 * @param {string} $variationAttribute - Attribute Value
 */
function handleSwatchBasedOnProductSet(productSetContainer, attr, swatchHtml, label, $attrValue, $variationAttribute) {
    if (quantityHelper.isProductSetavailable()) {
        $(productSetContainer).find('label.' + attr.id).html(label);
        $(productSetContainer).find('.quickbuy--color-' + attr.id).html(swatchHtml);
        $(productSetContainer).find($attrValue).attr('aria-checked', 'true');
        $(productSetContainer).find($variationAttribute).attr('aria-checked', 'true');
    } else {
        $('label.' + attr.id).html(label);
        $('.quickbuy--color-' + attr.id).html(swatchHtml);
        $attrValue.attr('aria-checked', 'true');
        $variationAttribute.attr('aria-checked', 'true');
    }
}

/**
 * Process the swatch attributes
 * @param {string} attrValue - Attribute Value
 * @param {Object} attr - Attribute
 * @param {jQuery} productSetContainer - DOM container for a given product in product set
 * @param {Object[]} $attrValue - Attribute coded value
 * @param {string} $variationAttribute - Attribute Value
 */
function handleSwatchAttr(attrValue, attr, productSetContainer, $attrValue, $variationAttribute, productId) {
    if (attrValue.selected) {
        var swatchImageUrl = attrValue.images.swatch.length > 0 ? attrValue.images.swatch[0].url : '';
        var label = '<span class="variation__attr--name" tabindex="0">' + attr.displayName + '</span><span class="variation__attr--value" tabindex="0">' + attr.displayValue + '</span>';
        var swatchHtml = '<li  class="quickbuy--variant-color-item single-swatch" role="radio" aria-checked="true" aria-label="' + attrValue.displayValue + '"  tabindex="0" style="background-image: url(' + swatchImageUrl + ')"></li>';
        handleSwatchBasedOnProductSet(productSetContainer, attr, swatchHtml, label, $attrValue, $variationAttribute);
    } else {
        $attrValue.attr('aria-checked', 'false');
        $variationAttribute.attr('aria-checked', 'false');
    }

    if (attrValue.url) {
        $attrValue.attr('data-url', attrValue.url);
    } else {
        $attrValue.removeAttr('data-url');
    }
    let attributeId = `${productId}_${attr.id}item-${attrValue.value}`;
    $attrValue =  productSetContainer ? $(`#${attributeId}`, productSetContainer) : $(`#${attributeId}`);
    if (!$attrValue.length && attrValue.selectable) {
        let $floatingBucket = $('.js-floatingColorList');
        if ($floatingBucket.length) {
            var swatchValues = `<li class="variant-list__item updated ${!attrValue.selectable && attr.id == 'colorCode' ? 'd-none' : ''}">
                                    <input type="radio" id="${productId}_${attr.id}item-${attrValue.value}" class="d-none ds-radio-swatch variant-color-item variant-${attr.id}" role="radio" aria-checked="${attrValue.selected}" data-attr-value="${attrValue.value}" data-display-id='${attr.id}' data-url="${attrValue.url}" tabindex="0">
                                    <label for="${productId}_${attr.id}item-${attrValue.value}">
                                        <span id="variation_element_${productId}_${attr.id}item-${attrValue.value}" style="background-image: url(${attrValue.images.swatch.length !== 0 ? attrValue.images.swatch[0].url : ''})" aria-label="${attrValue.displayValue}" tabindex="0" role="radio" aria-checked="${attrValue.selected}"></span>
                                        <span class="d-none">${attrValue.displayValue}</span>
                                    </label>
                                </li>`;
            $floatingBucket.removeClass('d-none').append(swatchValues);
        }
    }
}

/**
 * Process attribute values in QBB associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {boolean} strikeThroughStatus - Having strikeThroughStatus true or false
 */
function renderSwatchValuesQBB(attr) {
    var qbbAttrValues = attr.values;
    if (attr.isGroupedByPrice) {
        qbbAttrValues = attr.values.flatMap(obj => obj.values);
    }
    qbbAttrValues.forEach(function (attrValue, index) {
        let qbbAttributeId = `${attr.id}qitem-${attrValue.value}`;
        let $qbbAttrValue = $(`#${qbbAttributeId}`);
        $qbbAttrValue.attr('aria-checked', attrValue.selected ? 'true' : 'false');
        $qbbAttrValue.closest('li').toggleClass('colorOOS', !attrValue.selectable);

        if (!$qbbAttrValue.length && attrValue.selectable && attr.showFloatingColorGroup) {
            let swatchValues = `<li class="variant-list__item"><input type="radio" id="${attr.id}qitem-${attrValue.value}" class='d-none ${attr.id}quick-${attrValue.value} quickbuy--variant-color-item ds-radio-swatch variant-${attr.id}' role='radio' aria-checked="${attrValue.selected}" aria-label="${attrValue.displayValue}" data-attr-value="${attrValue.value}" data-display-id='${attr.id}' data-url="${attrValue.url}" tabindex="0" />
                <label for="${attr.id}qitem-${attrValue.value}">
                    <span style="background-image: url(${attrValue.images.swatch.length !== 0 ? attrValue.images.swatch[0].url : ''})"></span>
                    <span class="d-none">${attrValue.displayValue}</span>
                </label></li>`;

            $(`.attribute-${attr.id} .quickbuy--variant-selector-container ul#qcolors`).append(swatchValues);
        }
    });
}

function updateSwatchDisplay($swatch, isVisible) {
    $swatch.closest('li').toggleClass('colorOOS', !isVisible);
}

function processIndividualSwatch($attrValue, attrValue, attr, productSetContainer, $variationAttribute, isTeaserProduct, productId) {
    const isVisible = attrValue.selectable || isTeaserProduct;
    updateSwatchDisplay($attrValue, isVisible);

    if (isVisible) {
        handleSwatchAttr(attrValue, attr, productSetContainer, $attrValue, $variationAttribute, productId);
    }
}

function processSwatches(attr, productSetContainer, productId, isTeaserProduct, isGroupedByPrice) {
    const processValues = (values) => {
        values.forEach((attrValue, indexAttr) => {
            let attributeId = `${productId}_${attr.id}item-${attrValue.value}`;
            let $attrValue =  productSetContainer ? $(`#${attributeId}`, productSetContainer) : $(`#${attributeId}`);
            const $variationAttribute = $(`#variation_element_${attributeId}`);

            processIndividualSwatch($attrValue, attrValue, attr, productSetContainer, $variationAttribute, isTeaserProduct, productId);
        });
    };

    if (isGroupedByPrice) {
        attr.values.forEach(attrGroup => processValues(attrGroup.values));
    } else {
        processValues(attr.values);
    }

    var $colorLists = $('.variation-spacing-colorCode .variant-list');
    $colorLists.each(function () {
        let $elem = $(this);
        let $visibleColorSwatch = $elem.find('.variant-list__item:not(.colorOOS)');
        if (!$visibleColorSwatch.length) {
            $elem.addClass('d-none');
            $elem.prev().addClass('d-none');
        } else {
            $elem.removeClass('d-none');
            $elem.prev().removeClass('d-none');
        }
    });
}

function processSwatchValues(attr, strikeThroughStatus, productSetContainer, productId, attrId, isTeaserProduct = false) {
    if (!quantityHelper.isProductSetavailable()) {
        renderSwatchValuesQBB(attr, strikeThroughStatus);
    }

    const isGroupedByPrice = attr.isGroupedByPrice;
    processSwatches(attr, productSetContainer, productId, isTeaserProduct, isGroupedByPrice);
}


module.exports = {
    processSwatchValues : processSwatchValues
};
