import quickView from './product/quickView';
import productTile from 'base_global/product/productTile';
import search from 'base_global/search/search';
import pagedesignerCustom from 'base_global/search/pagedesignerCustom';
import sizeGuide from 'base_global/product/sizeGuide';
var processInclude = require('base/util');

// eslint-disable-next-line no-undef
$(document).ready(function () {
    processInclude(quickView);
    processInclude(productTile);
    processInclude(search);
    processInclude(pagedesignerCustom);
    processInclude(sizeGuide);
});
