let utils = require('../utils');

/**
 * Generates the modal window on the first call.
 *
 */
function getSizeGuideModal() {
    let isMobile = utils.getCurrentBreakpoint() === 'mobile';
    let modalClassNames = isMobile ? 'slide-in' : 'fade';
    var htmlString = `
        <div class="modal modal-animated size-guide__popup ${modalClassNames}" id="sizeGuidePopup" role="dialog" aria-labelledby="sizeGuideModal">
        <span class="enter-message sr-only"></span>
        <div class="modal-dialog modal-dialog-centered">
            <!-- Modal content-->
            <div class="modal-content">
                <button type="button" class="close size-guide__close ds-btn-circle sm" data-dismiss="modal" aria-label="Close">
                    <svg class="ds-block">
                        <use xlink:href="#close-darkgrey"></use>
                    </svg>
                    <svg class="ds-hover">
                        <use xlink:href="#close-white"></use>
                    </svg>
                </button>
                <div class="modal-body justify-content-center">
                </div>
            </div>
        </div>
    </div>`;
    $('body').append(htmlString);
}

module.exports = {
    QuickViewSideGuide: function () {
        $(document).on('click', '.size-guide', function () {
            $('.size-guide__popup').remove();
            var sizeGuideUrl = $(this).data('sizeguideurl');
            $.ajax({
                url: sizeGuideUrl,
                method: 'GET',
                success: function (data) {
                    var sizeGuideData = data.content;
                    getSizeGuideModal();
                    $('#sizeGuidePopup .modal-body').html(sizeGuideData);
                    $('#sizeGuidebtn').click();
                }
            });
        });
        $('body').on('hidden.bs.modal', '#sizeGuidePopup', function () {
            // eslint-disable-next-line
            if ($('.modal-backdrop.show').length || $('.quickbuy__backdrop.show').length) { 
                $('html').addClass('hide-scroll-and-lock');
            }
        });
    }
};
