import variationHelper from './variationHelper';
var base = require('base/product/base');
var tealiumEnable = require('int_tealium_sfra/tealiumEnable');
var quantityHelper = require('../quantitySelectorHelper');
var constants = require('../constants');
var utils = require('../utils');

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));
    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}


/**
 * One Size product identification
 *
 * @param {string} sizeVariation - to find out product is one size or not
*  @return {boolean} - boolean for one size product identification.
 */
function isOneSizeProduct(sizeVariation) {
    return sizeVariation.id.toLowerCase() === 'one size' || sizeVariation.id.toLowerCase() === 'onesize';
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog choose-bonus-product-dialog" '
        + 'data-total-qty="' + data.maxBonusItems + '"'
        + 'data-UUID="' + data.uuid + '"'
        + 'data-pliUUID="' + data.pliUUID + '"'
        + 'data-addToCartUrl="' + data.addToCartUrl + '"'
        + 'data-pageStart="0"'
        + 'data-pageSize="' + data.pageSize + '"'
        + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
        + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <span class="">' + data.labels.selectprods + '</span>'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    utils.updateCartCount(response.quantityTotal);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
            + response.message
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var isOneSize = isOneSizeProduct(response.product.variationAttributes[1].values[0]);
    var availabilityValue = '';
    var preOrderStatus = '';
    var availabilityMessages = response.product.availability.messages;
    var imgbadges = '';
    var finalSale = '';
    var saleBadges = '';
    var responseMessage = response.product.badgeSkuLevelStatus.preOrderMessage;
    var responseMessageDate = response.product.badgeSkuLevelStatus.preOrderMessageDate;
    if (response.product.badgeSkuLevelStatus.preOrderStatus) {
        preOrderStatus += '<div class="pre-order"> <span>' + responseMessage + '</span> <span class="pre-order-date">' + responseMessageDate + '</span> </div>';
    }
    if (response.product.availability.inStockValue.length === 0 && (response.product.readyToOrder || isOneSize)) {
        availabilityMessages.forEach(function (message) {
            availabilityValue += `<li><div class="${response.product.availability.cartMessage.length ? '' : 'page-error'}">${message}</div></li>`;
        });
    }

    if (response.product.badgeSkuLevelStatus.newBadge) {
        imgbadges += response.product.badgeSkuLevelStatus.newBadge;
        imgbadges += response.product.ImagebadgeSkuLevelStatus.imageBadge1 || response.product.ImagebadgeSkuLevelStatus.imageBadge2 ? ' , ' : '';
    }

    if (response.product.ImagebadgeSkuLevelStatus.imageBadge1) {
        imgbadges += response.product.ImagebadgeSkuLevelStatus.imageBadge1;
    } else if (response.product.ImagebadgeSkuLevelStatus.imageBadge2) {
        imgbadges += response.product.ImagebadgeSkuLevelStatus.imageBadge2;
    }

    if (!response.product.badgeSkuLevelStatus.availabilityStatus && response.product.availability.inStockValue.length !== 0) {
        saleBadges += `<div class="product-detail__salebadges-text inventory-msg">${response.product.availability.inStockValue[0]} </div>`;
    }
    if (response.product.badgeSkuLevelStatus.finalSalestatus) {
        saleBadges += '<div class="custom-error product-detail__salebadges-text">Final Sale</div>';
    }
    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources,
        preOrderStatus: preOrderStatus,
        badges: imgbadges,
        saleBadges: saleBadges,
        finalSale: finalSale
    });
}

/**
 * Handle selectable non swatch attributes
 * @param {jQuery} $this - DOM element
 * @param {string} attrValue - Attribute Value
 */
function handleSelectableEachNonSwatchAttr($this, attrValue) {
    if (!attrValue.selectable) {
        $this.attr('disabled', '');
        // $attrValue.attr('hidden', '');
    }
    if (attrValue.selected) {
        $this.attr('aria-checked', 'true');
    } else {
        $this.attr('aria-checked', 'false');
    }
}

/**
 * Process the Non-Swatch label attributes
 * @param {jQuery} productSetContainer - DOM container for a given product in product set
 * @param {string} attrValue - Attribute Value
 * @param {Object} attr - Attribute
 * @param {string} nonSwatchLabel - Swatch label
 * @param {Object[]} $attrValue - Attribute coded value
 * @param {string} $variationAttribute - Attribute Value
 */
function handleNonSwatchLabelAttrs(productSetContainer, attrValue, attr, nonSwatchLabel, $attrValue, $variationAttribute) {
    var isOneSize = isOneSizeProduct(attrValue);
    if (!attrValue.selectable) {
        $attrValue.attr('disabled', '');
    }
    if ((attrValue.selected || isOneSize) && quantityHelper.isProductSetavailable()) {
        $(productSetContainer).find('label.' + attr.id).html(`<p class="variation__attr--name d-inline mb-0" tabindex="0">${attr.displayName}</p>  <p class="variation__attr--value d-inline mb-0" tabindex="0">${isOneSize ? attrValue.displayValue : attr.displayValue}</p>`);
        if (nonSwatchLabel !== '') {
            $(productSetContainer).find('.size-label').html(nonSwatchLabel);
        }
        $(productSetContainer).find($attrValue).attr('aria-checked', 'true');
        $(productSetContainer).find($variationAttribute).attr('aria-checked', 'true');
    } else if ((attrValue.selected || isOneSize) && !quantityHelper.isProductSetavailable()) {
        $('label.' + attr.id).html(`<p class="variation__attr--name d-inline mb-0" tabindex="0">${attr.displayName}</p>  <p class="variation__attr--value d-inline mb-0" tabindex="0">${isOneSize ? attrValue.displayValue : attr.displayValue}</p>`);
        if (nonSwatchLabel !== '') {
            $('.size-label').html(nonSwatchLabel);
        }
        $attrValue.attr('aria-checked', 'true');
        $variationAttribute.attr('aria-checked', 'true');
    } else {
        $attrValue.attr('aria-checked', 'false');
        $variationAttribute.attr('aria-checked', 'false');
    }
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {boolean} strikeThroughStatus - Having strikeThroughStatus true or false
 * @param {boolean} nonSwatchLabel - Non swatch label
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} productSetContainer - DOM container for a given product in a product set
 * @param {string} productId - unique id of product in string
 */
// eslint-disable-next-line
function renderNonSwatchValues(attr, strikeThroughStatus, nonSwatchLabel, productSetContainer, productId, attrId) {
    var nonSwatchValues = '';
    var variantSelector = $('.variation-spacing-' + attr.id + ' .variant-selector-container.size-container-' + productId + ' ul');
    var selectorLabels = variantSelector.find('label');
    attr.values.forEach(function (attrValue, index) {
        if (!(!strikeThroughStatus && !attrValue.selectable)) {
            // eslint-disable-next-line
            var disableState = !attrValue.selectable ? `disabled` : ``;
            // eslint-disable-next-line
            nonSwatchValues += `<input class="d-none ds-radio-size variant-item variant-` + attr.id + `" type="radio" id="` + productId + `_` + attr.id + `item` + index + `" aria-checked="` + attrValue.selected + `" data-attr-value="` + attrValue.value + `" data-url="` + attrValue.url + `" data-display-id='` + attr.id + `' tabindex="0" ` + disableState + `>
            <label for="` + productId + '_' + attr.id + 'item' + index + '" aria-label="' + attrValue.displayValue + '">' + attrValue.displayValue + `
            </label>`;
        }
    });
    variantSelector.html(nonSwatchValues);
    if (attrId !== attr.id && $(selectorLabels[1]).hasClass('modal-error-border')) {
        var labels = variantSelector.find('label');
        labels.each(function () {
            $(this).addClass('modal-error-border');
        });
    }
    var $errorContainer = $(`.variant-error-${attrId}`);
    $errorContainer.addClass('hide');
}

/**
 * Process attribute values in QBB associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {boolean} strikeThroughStatus - Having strikeThroughStatus true or false
 * @param {boolean} nonSwatchLabel - Non swatch label
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} productSetContainer - DOM container for a given product in a product set
 * @param {string} productId - Unique ID of product in string
 */
// eslint-disable-next-line
function renderNonSwatchValuesQBB(attr, strikeThroughStatus, nonSwatchLabel, productSetContainer, productId, attrId) {
    var nonSwatchValues = '';
    var variantSelector = $('.attribute-' + attr.id + ' .quickbuy--variant-selector-container ul#qsizes');
    var selectorLabels = variantSelector.find('label');
    // if (attrId === attr.id) {
    attr.values.forEach(function (attrValue, index) {
        if (!(!strikeThroughStatus && !attrValue.selectable)) {
                // eslint-disable-next-line
                var disableState = !attrValue.selectable ? `disabled` : ``;
                // eslint-disable-next-line
                nonSwatchValues += `<input type="radio" id="` + attr.id + `qitem` + index + `" class='` + attr.id + `quick` + index + ` quickbuy--variant-item ds-radio-size variant-` + attr.id + `' role='radio' data-display-id='` + attr.id + `' aria-checked="` + attrValue.selected + `" aria-label="` + attrValue.displayName + `" data-attr-value="` + attrValue.value + `" data-url="` + attrValue.url + `" tabindex="0" ` + disableState + `/>
                <label for="` + attr.id + 'qitem' + index + '">' + attrValue.displayValue + '</label>';
        }
    });
    $('.attribute-' + attr.id + ' .quickbuy--variant-selector-container ul#qsizes').html(nonSwatchValues);

    if (attrId !== attr.id && $(selectorLabels[1]).hasClass('modal-error-border')) {
        var labels = variantSelector.find('label');
        labels.each(function () {
            $(this).addClass('modal-error-border');
        });
    }
    // }
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 * @param {boolean} strikeThroughStatus - Having strikeThroughStatus true or false
 * @param {boolean} nonSwatchLabel - Non swatch label
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} productSetContainer - DOM container for a given product in a product set
 * @param {string} productId - unique id of product in string
 */
// eslint-disable-next-line
function processNonSwatchValues(attr, strikeThroughStatus, nonSwatchLabel, productSetContainer, productId, attrId) {
    renderNonSwatchValues(attr, strikeThroughStatus, nonSwatchLabel, productSetContainer, productId, attrId);
    if (!quantityHelper.isProductSetavailable()) {
        renderNonSwatchValuesQBB(attr, strikeThroughStatus, nonSwatchLabel, productSetContainer, productId, attrId);
    }
    attr.values.forEach(function (attrValue, index) {
        var $attrValue = $(`#${productId}_${attr.id}item${index}`);
        var $variationAttribute = $(`#variation_element_${productId}_${attr.id}item${index}`);
        var $qattrValue = $('.' + attr.id + 'quick' + index);
        var $attrqValue = $('#' + attr.id + 'qitem' + index);
        const attributeId = `${productId}_${attr.id}item${index}`;
        const qattributeId = attr.id + 'qitem' + index;
        const isSelectableLabel = document.querySelectorAll("label[for='" + attributeId + "'], label[for='" + qattributeId + "']");   //eslint-disable-line
        if (quantityHelper.isProductSetavailable()) {
            $attrValue = $(productSetContainer).find(`#${attributeId}`);
            $attrqValue = $(productSetContainer).find('#' + attr.id + 'qitem' + index);
        }
        if (!(!strikeThroughStatus && !attrValue.selectable)) {
            $attrValue.show();
            $attrqValue.show();
            $attrValue.attr('data-url', attrValue.url).removeAttr('disabled');
            $attrqValue.attr('data-url', attrValue.url).removeAttr('disabled');
            handleNonSwatchLabelAttrs(productSetContainer, attrValue, attr, nonSwatchLabel, $attrValue, $variationAttribute);
            handleNonSwatchLabelAttrs(productSetContainer, attrValue, attr, nonSwatchLabel, $attrqValue, $variationAttribute);
            $qattrValue.each(function () {
                var $this = $(this);
                $this.show();
                $this.attr('data-url', attrValue.url).removeAttr('disabled');
                handleSelectableEachNonSwatchAttr($this, attrValue);
            });
        } else {
            $attrValue.hide();
            $attrqValue.hide();
            $qattrValue.each(function () {
                $(this).hide();
            });
        }
        if (!attrValue.selectable) {
            $attrValue.attr('disabled', '');
            $attrqValue.attr('disabled', '');

            if (!strikeThroughStatus) {
                $(isSelectableLabel).css('display', 'none');
            }
        } else {
            $attrValue.removeAttr('disabled');
            $attrqValue.removeAttr('disabled');
            $(isSelectableLabel).css('display', 'flex');
        }
    });
}

/**
 * Handle thumbnail image when click on pdp
 * @param {Object} modalContainer - conatiner of modal when user click on image on pdp
 */
function handleThumbnailClick(modalContainer) {
    $('.modal a.large-scroll').on('click', function (e) {
        e.preventDefault();
        $('.product-image--thumb.active').removeClass('active');
        let clickedImageIndex = +$(this).attr('href').split('#')[1];
        let scrollTo = $('.product-image--large[data-index=' + clickedImageIndex + ']');
        // eslint-disable-next-line
        let position = scrollTo.offset().top - modalContainer.offset().top + modalContainer.scrollTop();
        $('.modal').animate({
            scrollTop: position
        }, 500);
    });

    modalContainer.off('click').on('click', '.zoomed-images--mob .product-image', function () {
        var clbImg = $('.product-image__wrapper .product-image').find('img').data('iscolab');
        var gotoIndex = $(this).data('image-index');
        document.querySelector('.zoomed-images--mob').swiper.zoom.out(); // Zoom out to the initial state

        if (clbImg) {
            gotoIndex += 1;
        }
        document.querySelector('.product-images--primary').swiper.slideTo(gotoIndex, 0);
        modalContainer.modal('hide');
    });
}

/**
 *
function to handle previous error response from server
 * @param {string} attribute_id -
 * @param {jQuery} $productContainer - DOM element for current product
 *
 */
function handlePreviousErrorResponse(attribute_id, $productContainer) {
    if (attribute_id === 'colorCode') {
        $productContainer.find('span.variation__attr--name').removeClass('modal-error-color');
        $productContainer.find('input[type="radio"].ds-radio-size+label').removeClass('modal-error-border');
        $productContainer.find('.variant-error').addClass('hide');
    } else {
        $productContainer.find('.' + attribute_id + ' span.variation__attr--name').removeClass('modal-error-color');
        $productContainer.find('input[type="radio"].ds-radio-size.variant-' + attribute_id + ' + label').removeClass('modal-error-border');
        if ($('#modalQbbMobile').length) {
            $('#modalQbbMobile').find('.' + attribute_id + ' span.variation__attr--name').removeClass('modal-error-color');
            $('#modalQbbMobile').find('input[type="radio"].ds-radio-size.variant-' + attribute_id + ' + label').removeClass('modal-error-border');
        } else if ($('.variation__attr--quickbuy').length) {
            $('.variation__attr--quickbuy').find('.' + attribute_id + ' span.variation__attr--name').removeClass('modal-error-color');
            $('.variation__attr--quickbuy').find('input[type="radio"].ds-radio-size.variant-' + attribute_id + ' + label').removeClass('modal-error-border');
        }
    }
}

/**
 *
 Update Product URL for PDP
 * @param {jQuery} $productContainer - DOM element for current product
 * @param {string} injectUrl - URL which is injected to element which has productSetName href attribute
 */
function updateProductSetUrl($productContainer, injectUrl) {
    $productContainer.find('.productSetName').attr('href', injectUrl);
}


/**
 *
 function to handle validation UI on Quantity Select
 * @param {string} mode_of_variant - when user clicks on the add to bag button without selecting size then gives the value as size in string
 * @param {boolean} isExist - gives the boolean value if product is not exist and used for the error show
 */
function updateVariantOnQuantitySelectorStatus(mode_of_variant, mode_of_variant_value, isExist) {
    if (isExist.find('.' + mode_of_variant + ' span.variation__attr--name').html()) {
        const isVariantLabelAvailable = isExist.find('label.' + mode_of_variant + ' span.variation__attr--name').html();

        if (isVariantLabelAvailable.toLowerCase() === 'select ' + mode_of_variant_value.toLowerCase() + '' || isVariantLabelAvailable.toLowerCase() === mode_of_variant_value.toLowerCase()) {
            isExist.find('.' + mode_of_variant + ' span.variation__attr--name').addClass('modal-error-color');
            isExist.find('input[type="radio"].ds-radio-size.variant-' + mode_of_variant + ' + label').addClass('modal-error-border');
            isExist.find('.variant-error-' + mode_of_variant).removeClass('hide');
        }
    }
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {boolean} strikeThroughStatus - Having strikeThroughStatus true or false
 * @param {jQuery} productSetContainer - DOM element for a given product in a product set
 * @param {string} productId - unique id of product in string
 */
// eslint-disable-next-line
function updateAttrs(attrs, strikeThroughStatus, productSetContainer, productId, attrId) {
    var isOneSize = isOneSizeProduct(attrs[1].values[0]);
    var nonSwatchLabel = '';
    attrs.forEach(function (attr) {
        if (!attr.swatchable && attr.displayValue !== '') {
            nonSwatchLabel += attr.displayValue + ' X ';
        } else {
            productSetContainer.find(`label.${attr.attributeId}`).html(`<span class="variation__attr--name">${attr.displayName}</span>`);
            $('.modal--qbb label.' + attr.attributeId).html('<span class="variation__attr--name body-font sm">' + attr.displayName + '</span>');
            $('.js-quickbuy--container label.' + attr.attributeId).html('<span class="variation__attr--name body-font sm">' + attr.displayName + '</span>');
        }
        var selectorLabel = $('.variation-spacing-' + attr.id + ' .variant-selector-container ul label');
        var variantSelector = $('.attribute-' + attr.id + '.quickbuy--variant-selector-container ul#qsizes label');

        if (attrId !== attr.id && ($(selectorLabel[0]).hasClass('modal-error-border') || $(variantSelector[0]).hasClass('modal-error-border'))) {
            productSetContainer.find(`label.${attr.attributeId} span`).addClass('modal-error-color');
            $('.js-quickbuy--container .attribute-' + attr.id).find(`label.${attr.attributeId} span`).addClass('modal-error-color');
        }
    });

    if (nonSwatchLabel !== '') {
        nonSwatchLabel = nonSwatchLabel.substring(0, nonSwatchLabel.length - 3);
    } else {
        // eslint-disable-next-line no-lonely-if
        if (isOneSize) {
            $('.size-label').html(attrs[1].values[0].displayValue);
        } else {
            var sizeLabel = $('input.qbb-size-label-text').attr('value');
            $('.size-label').html(sizeLabel);
        }
    }

    attrs.forEach(function (attr) {
        if (attr.swatchable) {
            variationHelper.processSwatchValues(attr, strikeThroughStatus, productSetContainer, productId, attr.id);
        } else {
            processNonSwatchValues(attr, strikeThroughStatus, nonSwatchLabel, productSetContainer, productId, attr.id);
        }
    });
}

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(optionsHtml, $productContainer) {
    // Update options
    $productContainer.find('.product-options').empty().html(optionsHtml);
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                    + attribute.value + '</div>';
            });
        }
    });

    return html;
}

// eslint-disable-next-line
function deskColabImgs(clbImgs, imgSizeObj, clbImage, colabImg) {
    if (clbImgs.length > 0) {
        // eslint-disable-next-line
        clbImage = `<div class="product-image col-12">
        ${utils.renderPictureTag(clbImgs[0], { devices: { mobile: imgSizeObj.desktop.main }, className: 'd-block', isColab: true })}
            </div>`;
        colabImg.parent().addClass('has-image');
        colabImg.append(clbImage);
    }
}

// eslint-disable-next-line
function handleProductImageCls(productImageCls) {
    if (quantityHelper.isProductSetavailable()) {
        // eslint-disable-next-line
        productImageCls = 'col-xl-12';
    } else {
        // eslint-disable-next-line
        productImageCls = 'col-xl-6';
    }
    return productImageCls;
}

/**
 * Add class in zoomModal to for slide to selected slide.
 */
function addZoomModalClass() {
    $(document).on('click', '.swiper', function () {
        $('.swiper').removeClass('zoomModalClass');
        $(this).addClass('zoomModalClass');
    });
}

/**
 * Dynamically updates zoom modal from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {Object[]} imgSizeObj - Array of image dimensions
 */
function updateZoomModal(imgs, imgSizeObj) {
    var zoomModal = $('#productImagesModal');
    // var layoutImgMob = zoomModal.find('.zoomed-images--mob');
    var layoutImgDesk = zoomModal.find('.zoomed-images--desk');
    var deskThumb = layoutImgDesk.find('.image-thumbnails');
    var deskImgs = layoutImgDesk.find('.image-large');
    var imgThumb;
    var imgDesk;

    deskThumb.empty();
    deskImgs.empty();

    for (var j = 0; j < imgs.length; j++) {
        imgThumb = '<div class="product-image--thumb col-12" data-index="' + j + '" ><a href="#' + j + '" class="large-scroll" target=_self>' + utils.renderPictureTag(imgs[j], { devices: { desktop: imgSizeObj.desktop.zoomSmall, mobile: imgSizeObj.desktop.zoomSmall }, lazyload: true }) + '</a></div>';
        deskThumb.append(imgThumb);
    }

    for (var k = 0; k < imgs.length; k++) {
        imgDesk = '<div class="product-image--large col-12" data-index="' + k + '" ><a id="' + k + '">' + utils.renderPictureTag(imgs[k], { devices: { desktop: imgSizeObj.desktop.zoomLarge, mobile: imgSizeObj.desktop.zoomLarge }, lazyload: true }) + '</a></div>';
        deskImgs.append(imgDesk);
    }

    if ($('.product-set-detail').length) {
        utils.lazyLoadImage(zoomModal);
    }

    zoomModal.off('show.bs.modal').on('show.bs.modal', function () {
        utils.lazyLoadImage(zoomModal);
    });
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 * @param {string} productId - unique id of product in string
 */
// eslint-disable-next-line
function handleVariantResponse(response, $productContainer, productId, attrId) {
    var isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, response.product.strikeThroughStatus, $productContainer, productId, attrId);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);
            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images, colab images, alternate images and image dimentions
    var imgParams = response.product.imageDimentions;
    var primaryImageUrls = response.product.images.large;
    updateZoomModal(primaryImageUrls, imgParams);
    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer)
            : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
        $('.quickbuy--price .price').replaceWith(response.product.price.html);
    }

    // Update promotions
    $productContainer.find('.product-promotions').empty().html(response.product.promotionsHtml);
    if (response.product.showShippingPromotion) {
        var shippingPromoList = '';
        response.product.shippingPromotion.forEach((elem) => {
            if (elem.calloutMsg !== '') {
                shippingPromoList += '<li class="list__description"><span class="list__description--shipping">' + elem.calloutMsg + '</span><span class="list__description--seperator">,</span> </li>';
            }
        });
        $productContainer.find('.shipping-promotions').empty().html('<ul class="promotion__list promotion__list--shipping">' + shippingPromoList + '</ul>');
    } else {
        $productContainer.find('.shipping-promotions').empty();
    }

    // Update loyalty message
    if (response.product.loyaltyHtml) {
        $productContainer.find('.loyaltyPoint').empty().html(response.product.loyaltyHtml);
    }

    // update prices
    var $pricing = $('.product-detail__prices .sales .value');
    if (!response.product.orderable) {
        $pricing.html(constants.messages.OOS_status);
    } else {
        $productContainer.find('.product-detail__prices').empty().html(response.product.price.html);
    }
    updateAvailability(response, $productContainer);
    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').removeAttr('disabled');
    }
    // price null check on AddToBag CTA
    utils.disableButton($('.add-to-cart-actions button.add-to-cart'), response.product.price.salesDisplayPrice);
    // price null check on AddToBag CTA QBB
    utils.disableButton($('.cart__button--desktop button.add-to-cart'), response.product.price.salesDisplayPrice);

    // Update attributes
    $productContainer.find('.main-attributes').empty().html(getAttributesHtml(response.product.attributes));
}

// eslint-disable-next-line
function unCheckedVariation($errorContainer, $elementLabel, displayId, qbbBtn) {
    if (!$errorContainer.hasClass('show')) {
        $errorContainer.addClass('show');
    }
    if (displayId === 'size') {
        $('.size .variation__attr--name').addClass('custom-error');
    }
    if (qbbBtn) {
        $('.js-quickbuy--container .quickBuy-nonswatch-popup').removeClass('d-none');
        $('.size--drop use').attr('xlink:href', '#chevron-16px-up');
        $('.quickbuy__backdrop.js-sizing').removeClass('d-none');
    }

    if (!$elementLabel.hasClass('custom-error')) {
        $elementLabel.addClass('custom-error');
    }
}

// eslint-disable-next-line
function handleVariations(displayId, $errorContainer, $elementLabel, qbbBtn) {
    if ($('button.add-to-cart').is(':disabled')) {
        $('.variant-list').each(function () {
            // eslint-disable-next-line
            displayId = $(this).attr('data-display-id');
            if ($('.variant-' + displayId + '[aria-checked="true"]').length === 0) {
                // eslint-disable-next-line
                $errorContainer = $('.variant-error-' + displayId);
                // eslint-disable-next-line
                $elementLabel = $('.' + displayId);
                $errorContainer.removeClass('hide');
                unCheckedVariation($errorContainer, $elementLabel, displayId, qbbBtn);
            }
        });
    }
}

/**
 * Validate the mandatory fields and show/remove error message
 * @param {string} attrId - event trigger from
 * @param {boolean} qbbBtn - qbb button true or false
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function attributeValidation(attrId, qbbBtn, $productContainer) {
    var displayId = attrId;
    var $errorContainer = $(`.variant-error-${displayId}:not(.hide)`);
    if (quantityHelper.isProductSetavailable()) {
        $errorContainer = $productContainer.find('.variant-error-' + displayId);
    }
    var $elementLabel = $productContainer.find('.' + displayId);
    if (attrId === 'addToCart') {
        handleVariations(displayId, $errorContainer, $elementLabel, qbbBtn);
    } else {
        $errorContainer.removeClass('show');
        if (!$errorContainer.hasClass('hide')) {
            $errorContainer.addClass('hide');
        }
        $productContainer.find('.size .variation__attr--name').removeClass('custom-error');
        if ($elementLabel.hasClass('custom-error')) {
            $elementLabel.removeClass('custom-error');
        }
    }
}

/**
 * Update quick buy bar container
 *
 * @param {Object} data - Response object
 */
function updateAvailabilityForQBB(data) {
    var availabilityValue = '';
    var availabilityMessages = data.product.availability.messages;
    var imgbadges = '';
    var finalSale = '';
    var saleBadges = '';
    $('.qbbAvailabilityError').remove();
    if (data.product.ImagebadgeSkuLevelStatus.imageBadge1) {
        imgbadges += data.product.ImagebadgeSkuLevelStatus.imageBadge1;
    } else if (data.product.ImagebadgeSkuLevelStatus.imageBadge2) {
        imgbadges += data.product.ImagebadgeSkuLevelStatus.imageBadge2;
    } else if (data.product.badgeSkuLevelStatus.newBadge) {
        imgbadges += data.product.badgeSkuLevelStatus.newBadge;
    }
    $('.quickbuy--imgBadges').html(imgbadges);
    availabilityMessages.forEach(function (message) {
        availabilityValue += `<li><div><p class="${data.product.availability.cartMessage.length ? '' : 'page-error'}">${message}</p></div></li>`;
    });
    if (!data.product.badgeSkuLevelStatus.availabilityStatus) {
        saleBadges += data.product.availability.inStockValue;
        availabilityValue = '';
    }
    if (data.product.badgeSkuLevelStatus.finalSalestatus) {
        finalSale += 'Final Sale';
    }
    $('.product-detail__salebadges-qbb-onload').addClass('d-none');
    if (data.product.readyToOrder) {
        $('.availability-msg-quickbuy').removeClass('d-none').empty().html(availabilityValue);
        $('.product-detail__salebadges-quickbuy').removeClass('d-none').empty().html('<p>' + saleBadges + '</p>');
    }
    $('.product-detail__finalSaleFlag-quickbuy').removeClass('d-none').empty().html('<p>' + finalSale + '</p>');
    if (!availabilityValue.length) {
        $('.availability-msg-quickbuy').addClass('d-none');
    }
    if (!saleBadges.length) {
        $('.product-detail__salebadges-quickbuy').addClass('d-none');
    }
    if (!finalSale.length) {
        $('.product-detail__finalSaleFlag-quickbuy').addClass('d-none');
    }
}

/**
 * Update quick buy bar container
 *
 * @param {Object} data - Response object
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function updateQuickBuy(data, $productContainer,pid, attrId) {
    var isOneSize = isOneSizeProduct(data.product.variationAttributes[1].values[0]);
    var buttonHtmlQbb = 'Add to Bag';
    var buttonHtmlQbbM = 'Add to Bag';
    var buttonHtmlSelectSize = 'Select a Size';
    var currentDisplayPrice = ` - ${data.product.price.type === 'range' ? data.product.price.salesDisplayRange : data.product.price.salesDisplayPrice}`;
    $('button.add-to-cart').data('defaultdisplayprice', currentDisplayPrice);
    var buttonSalesPrice = data.product.isAddToBagPrice ? ' - ' + data.product.price.salesDisplayPrice : '';
    if (data.product.badgeSkuLevelStatus.preOrderStatus) {
        buttonHtmlQbb = data.product.badgeSkuLevelStatus.preOrderBadge;
        buttonHtmlQbbM = data.product.badgeSkuLevelStatus.preOrderBadge;
    }
    var prodImageQbb = '';
    if (data.product.images.large.length > 0) {
        prodImageQbb = utils.renderPictureTag(data.product.images.large[0], { devices: { desktop: data.product.imageDimentions.desktop.quickBuy.product, mobile: data.product.imageDimentions.desktop.quickBuy.product }, lazyload: true });
    } else {
        prodImageQbb = '<img class="product-image" alt="" src="data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAFCAQAAADIpIVQAAAAEUlEQVR42mP89Z8BDBiJYAAA/CsJ44FW0NkAAAAASUVORK5CYII=" />';
    }

    $('.quickbuy--image__wrapper').html(prodImageQbb);
    $('.quickbuyMob--image__wrapper').html(prodImageQbb);

    updateAvailabilityForQBB(data);
    if (data.product.variationAttributes) {
        updateAttrs(data.product.variationAttributes, data.product.strikeThroughStatus, $productContainer,pid, attrId);
    }
    if (!data.product.readyToOrder && !isOneSize) {
        if (quantityHelper.isProductSetavailable()) {
            $productContainer.find('button.add-to-cart').attr('data-readytoorder', false);
            $productContainer.find('.js-btn-qbb-mob').html(buttonHtmlQbbM);
        } else {
            $('.add-to-cart').attr('data-readytoorder', false);
            $('.js-btn-qbb-mob').html(buttonHtmlQbbM);
        }
        if (data.product.orderable) {
            $('.quickbuy--container button.add-to-cart').html(buttonHtmlQbb + currentDisplayPrice);
            $('.js-btn-qbb-mob').html(buttonHtmlQbbM + currentDisplayPrice);
            $('button.add-to-cart').html(buttonHtmlSelectSize);
        } else {
            $('.quickbuy--container button.add-to-cart').html(buttonHtmlQbb);
            $('.js-btn-qbb-mob').html(buttonHtmlQbbM);
            $('button.add-to-cart').html(buttonHtmlQbbM);
        }
    } else {
        if (quantityHelper.isProductSetavailable()) {
            $productContainer.find('button.add-to-cart').attr('data-readytoorder', true);
            $productContainer.find('.js-btn-qbb-mob').html(buttonHtmlQbbM + buttonSalesPrice);
            $productContainer.find('button.add-to-cart').html(buttonHtmlQbbM + buttonSalesPrice);
        } else {
            $('button.add-to-cart').attr('data-readytoorder', true);
            $('.js-btn-qbb-mob').html(buttonHtmlQbbM + buttonSalesPrice);
            $('button.add-to-cart').html(buttonHtmlQbbM + buttonSalesPrice);
        }
        $('.quickbuy--container button.add-to-cart').html(buttonHtmlQbb + buttonSalesPrice);
        if (data.product.price.salesDisplayPrice === null) {
            $('button.add-to-cart').attr('data-readytoorder', true);
        }
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 * @param {string} attrId - event trigger from
 * @param {string} selectedAttrValue - attr value
 */
function attributeSelect(selectedValueUrl, $productContainer, attrId, selectedAttrValue, isViewAllColorPanel) {
    var $defaultColor = $('div.variant-selector-container ul li').find('input[aria-checked="true"]').attr('data-attr-value');
    if (selectedValueUrl) {
        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                $('.product-detail__page_level_error').addClass('d-none');
                var modalContainer = $('#productImagesModal');
                var isOneSize = isOneSizeProduct(data.product.variationAttributes[1].values[0]);
                var pid;
                var hideAfterpay = $('.hide-afterpay-preorder');
                if ($productContainer.attr('data-pid')) {
                    pid = $productContainer.attr('data-pid');
                } else {
                    pid = $('.product-detail.product-wrapper').attr('data-pid');
                }
                $productContainer.find('.page-quantity-error').empty();
                handleVariantResponse(data, $productContainer, pid, attrId);
                handlePreviousErrorResponse(attrId, $productContainer);
                updateOptions(data.product.optionsHtml, $productContainer);
                if (data.product.badgeSkuLevelStatus.preOrderStatus) {
                    hideAfterpay.addClass('d-none');
                } else {
                    hideAfterpay.removeClass('d-none');
                }
                quantityHelper.updateQuantities(data.product, $productContainer, pid);
                attributeValidation(attrId, false, $productContainer);
                handleThumbnailClick(modalContainer);
                if (!isOneSize && !data.product.oneSizeSKUID) {
                    if (quantityHelper.isProductSetavailable()) {
                        $productContainer.find('.add-to-cart').attr('data-cur-pid', data.product.id);
                    } else {
                        $('.add-to-cart').attr('data-cur-pid', data.product.id);
                    }
                } else {
                    // eslint-disable-next-line
                    if (quantityHelper.isProductSetavailable()) {
                        $productContainer.find('.add-to-cart').attr('data-cur-pid', data.product.oneSizeSKUID);
                    } else {
                        $('.add-to-cart').attr('data-cur-pid', data.product.oneSizeSKUID);
                    }
                }
                $('.add-to-cart').attr('data-category-id', data.product.productCgId);
                updateQuickBuy(data, $productContainer,pid, attrId);
                updateProductSetUrl($productContainer, data.product.selectedProductUrl);
                $('body').trigger('product:afterAttributeSelect', { data: data, container: $productContainer, attrId: attrId, selectedAttrValue: selectedAttrValue });
                $.spinner().stop();
                if (attrId && attrId === 'colorcode') {
                    if ($defaultColor && selectedAttrValue && (selectedAttrValue.toString().toLowerCase() !== $defaultColor.toString().toLowerCase())) {
                        tealiumEnable.getAnalyticsData('selectColorOrSizeEvent', data, attrId, isViewAllColorPanel);
                    }
                } else {
                    tealiumEnable.getAnalyticsData('selectColorOrSizeEvent', data, attrId, isViewAllColorPanel);
                }
            },
            error: function () {
                $('.product-detail__page_level_error').removeClass('d-none');
                $.spinner().stop();
            }
        });
    }
}

// eslint-disable-next-line
function addToCartTimeOutMotion(name, current) {
    current.removeClass('active');
    current.removeClass('progress');
    current.html(name);
}

// eslint-disable-next-line
function addToCartTimeOutProgress(current) {
    current.addClass('progress');
}

/**
 * Handle error message when product is added more than max quantity allowed
 * @param {string} message - error message
 */
// eslint-disable-next-line
function handleMaxQuantityError(message, $productContainer) {
    var $variantSection = $('.quickbuy--mobile').find('.variant-section');
    $productContainer.find('.page-quantity-error').empty().html(utils.showErrorMessage(message));
    $('.qbbAvailabilityError').remove();
    $(`<span class="page-error qbbAvailabilityError"><svg class="icon-utility-alert-red"><use xlink:href="#alert-red"></use></svg>${message}</span>`).insertAfter($variantSection);
    $('.quickbuy--container').find('.add-to-cart-container').prepend(`<span class="page-error qbbAvailabilityError"><svg class="icon-utility-alert-red"><use xlink:href="#alert-red"></use></svg>${message}</span>`);
}

// eslint-disable-next-line valid-jsdoc
/**
 * Handle variant error
 * @param {Object} $this - Element
 * @param {string} productID - error message
 */
function handleVariantError($this, productID, qbbBtn) {
    const variants = $('body').find('.variant-list:not([data-display-id="colorCode"])').toArray();
    const isExistQbbMobile = $this.closest('#modalQbbMobile').find('.product-detail__attributes .attribute');
    const isExistQbbDesk = $('.variation__attr--quickbuy').find('.product-detail__attributes .attribute');
    const isExistPdpControl = $this.closest('.product-detail.pid-' + productID).find('.product-detail__attributes #pdpAttribute');
    variants.forEach(elem => {
        const variant = $(elem)[0].dataset.displayId;
        const variantValue = $(elem)[0].dataset.displayValue;
        let variantItemPdpControl = isExistPdpControl.find('label.' + variant);
        let variantItemQbbDesk = isExistQbbDesk.find('label.' + variant);
        let variantItemQbbMobile = isExistQbbMobile.find('label.' + variant);
        if (variantItemPdpControl.length) {
            updateVariantOnQuantitySelectorStatus(variant, variantValue, isExistPdpControl);
        }
        if (variantItemQbbDesk.length) {
            updateVariantOnQuantitySelectorStatus(variant, variantValue, isExistQbbDesk);
            if (qbbBtn) {
                let sizePopModal = $('.js-quickbuy--container');
                sizePopModal.find('.quickBuy-nonswatch-popup').addClass('show');
                $('.quickbuy--variant-size-wrapper').addClass('quickbuy-drop-arrow-up');
                sizePopModal.next('.js-sizing').addClass('show');
            } else {
                $('.quickbuy--variant-size-wrapper').removeClass('quickbuy-drop-arrow-up');
            }
        }
        if (variantItemQbbMobile.length) {
            updateVariantOnQuantitySelectorStatus(variant, variantValue, isExistQbbMobile);
        }
    });
}

/**
 * This function clear the zoomModal and update new product images on product-image click
 */
function handleSetProducts() {
    $(document).on('click', '.set-item .product-image', function () {
        var zoomModal = $('#productImagesModal');
        var layoutImgMob = zoomModal.find('.zoomed-images--mob');
        var layoutImgDesk = zoomModal.find('.zoomed-images--desk');
        var deskThumb = layoutImgDesk.find('.image-thumbnails');
        var deskImgs = layoutImgDesk.find('.image-large');
        layoutImgMob.empty();
        deskThumb.empty();
        deskImgs.empty();

        var selectedDataUrl = $(this).attr('data-url');
        var $productContainer = $(this).closest('.product-detail');
        attributeSelect(selectedDataUrl, $productContainer);
    });
}

export default {
    ...base,
    attributeSelect: attributeSelect,
    handleSetProducts: handleSetProducts,
    addZoomModalClass: addZoomModalClass,

    initImgCaro: function () {
        var modalContainer = $('#productImagesModal');
        handleThumbnailClick(modalContainer);
        $('#aboutAttribute .enable-drawer__button').on('click', function () {
            var currElem = $(this).closest('.enable-drawer');
            let accordionContent = currElem.children('.content');
            if (!currElem.attr('open')) {
                accordionContent.css('display', 'none');
                accordionContent.slideDown('slow');
                var accordianName = 'product details';
                tealiumEnable.getAnalyticsData('productAccordianAnalytics', accordianName);
            }
        });

        $('#aboutShipping .attributeBtn').on('click', function () {
            var currElem = $('#aboutShipping .attributeBtn');
            if (currElem.hasClass('collapsed')) {
                var accordianName = 'shipping and returns';
                tealiumEnable.getAnalyticsData('productAccordianAnalytics', accordianName);
            }
        });
    },

    addToCart: function () {
        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function (e) {
            if ($(this).closest('#quickViewModal').length === 0) {
                var $productContainer = $(this).closest('.set-item');
                if (!$productContainer.length) {
                    $productContainer = $(this).closest('.product-detail');
                }
                if (!$productContainer.length) {
                    $productContainer = $('.js-buying-controls').closest('.product-detail');
                }
                var qty = $('.quantity-dropdown option:selected').val();
                if (quantityHelper.isProductSetavailable()) {
                    qty = $productContainer.find('.quantity-dropdown option:selected').val();
                }
                var button = $(this);
                var pid = $(this).attr('data-cur-pid');
                var cgid = $(this).attr('data-category-id');
                var addToCartUrl = $('.add-to-cart-url').val();
                var readyToOder = $(this).attr('data-readytoorder');
                var pageType = $('.page.current').data('page');
                var csrf_token = $('.csrf-token').attr('data-csrf-value');
                var form = {
                    pid: pid,
                    quantity: qty,
                    pageType: pageType,
                    csrf_token: csrf_token,
                    cgid: cgid
                };

                if (addToCartUrl && readyToOder === 'true') {
                    $.ajax({
                        url: addToCartUrl,
                        method: 'POST',
                        data: form,
                        success: function (data) {
                            if (data.error) {
                                handleMaxQuantityError(data.message, $productContainer);
                            } else {
                                $productContainer.find('.page-quantity-error').empty();
                                $('.qbbAvailabilityError').remove();
                                $('#modalQbbMobile').modal('hide');
                                handlePostCartAdd(data);
                                $('body').trigger('product:afterAddToCart', data);
                                // tealium event
                                var setId = $('.product-set-detail').data('pid');
                                var cartAddMethod;
                                if ($('.add-to-cart').parent().parent().hasClass('productSet--attribute__addtocart')) {
                                    cartAddMethod = 'bundle';
                                }
                                // offsetX provides the offset in the X coordinate of the mouse pointer between that event and the padding edge of the target node. Same goes with offsetY
                                // In case of jquery .click(), the values will be 0.
                                if (e.offsetX === 0 && e.offsetY === 0) {
                                    cartAddMethod = 'pdp_fitfinder';
                                }
                                var eventName;
                                if (button.parent().parent().hasClass('js-qbb-analytics')) {
                                    eventName = 'cart_add,cart_add_quickbuysticky';
                                } else {
                                    eventName = 'cart_add,cart_open';
                                }
                                tealiumEnable.getAnalyticsData('addToCartEvent', data, form, cartAddMethod, setId, eventName);
                            }
                            $.spinner().stop();
                        },
                        error: function (data) {
                            let message = data.responseJSON.message;
                            let errorHandling = function () {
                                $.spinner().stop();
                                handleMaxQuantityError(message, $productContainer);
                                tealiumEnable.getAnalyticsData('serverError', data.responseJSON.errorCode);
                            };
                            if (data.responseJSON.csrfError) {
                                utils.handleCsrfToken(errorHandling);
                            } else {
                                errorHandling();
                            }
                        }
                    });
                }
            }
        });
    },

    colorAttribute: function () {
        $(document).on('click', '.js-buying-controls .variant-color-item:not([disabled],[aria-checked="true"]), .quickbuy--variant-color-item:not([disabled],[aria-checked="true"])', function (e) {
            e.preventDefault();
            var $productContainer = $('.product-detail');
            var selectedValueUrl = $(this).attr('data-url');
            var selectedAttrId = $(this).attr('data-display-id');
            var selectedAttrValue = $(this).attr('data-attr-value');
            $(this).siblings('.variant-color-item').attr('aria-checked', 'false');
            if (quantityHelper.isProductSetavailable()) {
                $productContainer = $(this).closest('.set-item.productSet-wrapper');
                $($productContainer).find(this).siblings('.variant-color-item').attr('aria-checked', 'false');
            }
            attributeSelect(selectedValueUrl, $productContainer, selectedAttrId, selectedAttrValue);
        });
    },

    selectAttribute: function () {
        $(document).on('click', '.js-buying-controls .variant-item:not([disabled]), .quickbuy--variant-item:not([disabled])', function (e) {
            e.preventDefault();
            var $productContainer = $('.product-detail');
            var selectedValueUrl = $(this).attr('data-url');
            var selectedAttrId = $(this).attr('data-display-id');

            if (quantityHelper.isProductSetavailable()) {
                $productContainer = $(this).closest('.set-item.productSet-wrapper');
            }
            attributeSelect(selectedValueUrl, $productContainer, selectedAttrId);
        });
    },

    quantitySelect: function () {
        $(document).on('change', '.js-buying-controls .quantity-dropdown select', function (e) {
            e.preventDefault();
            var $selectedOption = $('option:selected', $(this));
            var $productContainer = $('.product-detail');
            var selectedValueUrl = $selectedOption.data('url');
            if (quantityHelper.isProductSetavailable()) {
                $productContainer = $(this).closest('.set-item.productSet-wrapper');
            }
            attributeSelect(selectedValueUrl, $productContainer, '-');
        });
    },

    variationGroupSelect: function () {
        $('body').on('variationgroup:selected', (event, selectedValueUrl, selectedAttrId, selectedAttrValue) => {
            var $productContainer = $('.product-detail');
            setTimeout(() => window.scrollTo(0, 0), 150);
            attributeSelect(selectedValueUrl, $productContainer, selectedAttrId, selectedAttrValue, true);
        });
    },

    availability: function () {
        $(document).on('change', '.quantity-select', function (e) {
            e.preventDefault();
            if ($(this).closest('#quickViewModal').length === 0) {
                var $productContainer = $(this).closest('.product-detail');
                if (!$productContainer.length) {
                    $productContainer = $(this).closest('.modal-content').find('.product-quickview');
                }

                if ($('.bundle-items', $productContainer).length === 0) {
                    attributeSelect($(e.currentTarget).find('option:selected').data('url'),
                        $productContainer, '-');
                }
            }
        });
    },

    QuantitySelectorErrorWarning: function () {
        $(document).on('click', '.js-buying-controls .js-quantitySelector', function (e) {
            let containerData = e.target.nodeName !== 'SELECT' ? $(e.target).siblings('select') : e.target;
            var $this = $(this);
            var productID = $this.data('pid');
            if ($(containerData).attr('disabled')) {
                handleVariantError($this, productID);
            }
        });
    },

    variantValidation: function () {
        $(document).on('click', '.js-buying-controls .add-to-cart-container, .cart__button--desktop .add-to-cart-container', function (e) {
            e.preventDefault();
            var $productContainer = $(this).closest('.product-detail');
            var qbbBtn = $(this).parent().hasClass('cart__button--desktop');
            attributeValidation('addToCart', qbbBtn, $productContainer);
        });
    },

    imgCaroSelection: function () {
        var imgModal = $('#productImagesModal');
        var imgTile = imgModal.find('.product-image');
        var index;

        imgTile.on('click', function () {
            var colab = $('.product-image__wrapper .product-image').find('img').data('iscolab');

            index = $(this).data('index');

            if (colab) {
                index += 1;
            }
            try {
                document.querySelector('.zoomModalClass').swiper.slideTo(index, 0);
            } catch (err) {
                imgModal.modal('hide');
            }
        });
    },

    zoomModalAnimate: function () {
        var $modal = $('#productImagesModal');
        $modal.on('hide.bs.modal', function (e) {
            if ($modal.hasClass('show')) {
                e.stopPropagation();
                e.preventDefault();
                $modal.removeClass('show');
                setTimeout(() => $modal.modal('hide'), 300);
            }
        });
    },

    addToCartMotion: function () {
        $(document).on('click', '.js-buying-controls .add-to-cart-container button.add-to-cart, .cart__button--desktop .add-to-cart-container button.add-to-cart', function () {
            var productID = $(this).data('pid');
            var qbbBtn = $(this.closest('.quickbuy--desktop')).length;
            var $this = $('.add-to-cart-container.pid-' + productID);
            if ($this.find('button.add-to-cart').attr('data-readytoorder') === 'true') {
                var current = $this.find('.primary__button--dark, .ds-btn-block');
                var name = current.html();
                current.addClass('active');
                current.html('Adding...');
                setTimeout(addToCartTimeOutProgress, 200, current);
                setTimeout(addToCartTimeOutMotion, 800, name, current);
                if ($('.quickBuy-nonswatch-popup').hasClass('show')) {
                    $('.quickbuy--variant-size-wrapper').removeClass('quickbuy-drop-arrow-up');
                }
                if ($('.qbb-color-dropdown').hasClass('show')) {
                    $('.quickbuy--color-container-desk').removeClass('quickbuy-drop-arrow-up');
                }
            } else {
                handleVariantError($this, productID, qbbBtn);
            }
        });
    },

    fitAnalytics: function () {
        $(document).on('click', '#fitAnalyticsContainer a ', function () {
            tealiumEnable.getAnalyticsData('fitAnalytics');
        });
    },

    selectedSwiper: function () {
        var gotoIndex;
        $(document).on('click', '.product-images--primary .product-image', function () {
            const swiperMain = document.querySelector('.swiper').swiper;
            if ($(this).data('image-index') !== undefined) {
                gotoIndex = $(this).data('image-index');
            } else {
                gotoIndex = $(this).find('img').data('imageindex');
            }
            const swiperInstance = document.querySelector('.zoomed-images--mob').swiper;
            if (swiperInstance) {
                swiperInstance.slideTo(gotoIndex, 0);
            }
            swiperMain.zoom.out(); // Zoom out to the initial state
        });

        $(document).on('click', '#productImagesModal .close', function () {
            if ($('.zoomed-images--mob').length) {
                gotoIndex = $('.zoomed-images--mob .swiper-slide-active').data('image-index');
                const swiperInstance = document.querySelector('.product-images--primary').swiper;
                if (swiperInstance) {
                    swiperInstance.slideTo(gotoIndex, 0);
                }
            }
        });
    }
};

